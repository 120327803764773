/*--------------------------------------------------------------
# Local css 
--------------------------------------------------------------*/


body {
  font-family: 'Figtree' !important;
  font-style: normal;
  font-optical-sizing: auto;
  color: #222222;
  padding-bottom: 1px;
  text-rendering: auto;
  height: 100%;
  overflow: visible !important;
  padding-right: 0 !important;
}

/* button, input, optgroup, select, textarea, option{
  font-family: 'Figtree' !important;
} */

/* #root{
  overflow-x: hidden;
} */
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  height: 100%;
  overflow-y: visible;
}

p {
  margin-bottom: 0;
}

.text-gray {
  color: #D9D9D9;
}

.btn-Compare {
  background-color: #c3c8c4 !important;
  text-decoration: none;
  color: #fff;
  border-radius: 4px;
  border: none;
  outline: none;
  box-shadow: none;
  /* padding: 2px; */
  height: 35px;
  font-size: 16px;
}

.compare-active {
  background-color: #0F2213 !important;
  color: #FFFFFF !important;
  text-decoration: none;
  border-radius: 4px;
  border: none;
  outline: none;
  box-shadow: none;
  height: 35px;
}

.compare-active:hover {
  background-color: #1f4427 !important;
}

.btn-gray {
  background-color: #ddddde !important;
  border: 1px solid #ddddde !important;
  color: #000;
  width: 64%;
  height: 45px;
  border-radius: 8px;
}

.btn-gray-border {
  background-color: #fff !important;
  border: 1px solid #D9D9D9 !important;
  width: 100% !important;
  text-align: center;
}

/* .btn-gray-dark{
  background-color: #76777b !important;
  border: 1px solid #76777b !important;
  color: #fff;
  width: 64%;
  height: 45px;
} */

.form-select {
  border: 2px solid #d9d9d9 !important;
}

.table-body-color {
  background-color: #fcfcfc;
  /* padding-bottom: 5px; */
}

.table-gray {
  background-color: #d0d0d2;
}

.table-gray-old {
  background-color: #e9e9e9;
}

.heading-tr {
  background-color: #D0D0D2;
}

.border-gray {
  border-color: #E9E9E9 !important;
}

.search_row_old {
  padding-top: 1.5rem;
}

.search_row {
  padding-top: .8rem;
}

.nonewrep {
  text-wrap: nowrap;
  white-space: nowrap;
}

th,
td {
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

/* Define the animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  50% {
    opacity: 0.5;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply the animation to each table row */
tbody tr {
  animation: fadeIn 0.5s ease-out forwards;
  opacity: 0;
  /* Start with opacity 0 to be faded in */
}

/*--------------------------------------------------------------
# Local css 
--------------------------------------------------------------*/

@media only screen and (min-height: 100px) and (max-height: 700px) {
  .table-responsive {
    /* max-height: 90px; */
  }

  .fix-margin {
    margin-top: 100px;
  }

  /* .fix_incentive_all-margin{
    margin-top: 80px;
  } */
}

@media only screen and (min-height: 600px) and (max-height: 650px) {
  .fix-margin {
    margin-top: 160px;
  }

  .fix_incentive_all-margin {
    margin-top: 95px;
  }
}

@media only screen and (min-height: 650px) and (max-height: 700px) {
  .fix-margin {
    margin-top: 230px;
  }

  .fix_incentive_all-margin {
    margin-top: 1100px;
  }
}

@media only screen and (min-height: 700px) and (max-height: 750px) {
  .table-responsive {
    /* max-height: 290px; */
  }

  .fix-margin {
    margin-top: 270px;
  }

  .fix_incentive_all-margin {
    margin-top: 140px;
  }
}

@media only screen and (min-height: 750px) and (max-height: 800px) {
  .table-responsive {
    /* max-height: 380px; */
  }

  .fix-margin {
    margin-top: 380px;
  }

  .fix_incentive_all-margin {
    margin-top: 190px;
  }
}

@media only screen and (min-height: 800px) and (max-height: 850px) {
  .table-responsive {
    /* max-height: 380px; */
  }

  .fix-margin {
    margin-top: 360px;
  }

  .fix_incentive_all-margin {
    margin-top: 260px;
  }
}

@media only screen and (min-height: 850px) and (max-height: 900px) {
  .table-responsive {
    /* max-height: 430px; */
  }

  .fix-margin {
    margin-top: 410px;
  }

  .fix_incentive_all-margin {
    margin-top: 335px;
  }
}

@media only screen and (min-height: 900px) and (max-height: 1200px) {
  .table-responsive {
    /* max-height: 540px; */
  }

  .fix-margin {
    margin-top: 540px;
  }

  .fix_incentive_all-margin {
    margin-top: 420px;
  }

  .section-height {
    min-height: 70vh;
  }
}

@media only screen and (min-height: 1200px) and (max-height: 1400px) {
  .table-responsive {
    /* max-height: 840px; */
  }

  .section-height {
    min-height: 70vh;
  }

  .fix-margin {
    margin-top: 840px;
  }

  .fix_incentive_all-margin {
    margin-top: 740px;
  }
}

@media only screen and (min-height: 1400px) and (max-height: 2000px) {
  .table-responsive {
    /* max-height: 1490px; */
  }

  .section-height {
    min-height: 80vh;
  }

  .fix-margin {
    /* max-height: 1200px !important; */
    margin-top: 1430px;
  }

  .fix_incentive_all-margin {
    margin-top: 1410px;
  }

}

@media only screen and (min-height: 2000px) and (max-height: 4000px) {
  .table-responsive {
    /* max-height: 1990px; */
  }

  .fix-margin {
    /* max-height: 2000px !important; */
    margin-top: 2010px;
  }

  .fix_incentive_all-margin {
    margin-top: 1990px;
  }
}

/* @media only screen and (min-width: 2500px) and (max-width: 3000px) {
  .sticky-filter{
    padding-top: 20px;
    background-color: white;
    position: fixed;
    top:0;
    width:100%;
    padding-right: 1.8%;
    z-index:99999;
  }
  .table-div{
    margin-top: 12rem;
  }
  .incentive-all-table-div{
    margin-top: 19rem;
  }
}
@media only screen and (min-width: 2000px) and (max-width: 2500px) {
  .sticky-filter{
    padding-top: 20px;
    background-color: white;
    position: fixed;
    top:0;
    width:100%;
    padding-right: 2.5%;
    z-index:99999;
  }
  .table-div{
    margin-top: 12rem;
  }
  .incentive-all-table-div{
    margin-top: 19rem;
  }
}
@media only screen and (min-width: 1800px) and (max-width: 2000px) {
  .sticky-filter{
    padding-top: 20px;
    background-color: white;
    position: fixed;
    top:0;
    width:100%;
    padding-right: 2.8%;
    z-index:99999;
  }
  .table-div{
    margin-top: 12rem;
  }
  .incentive-all-table-div{
    margin-top: 19rem;
  }
}

@media only screen and (min-width: 1700px) and (max-width: 1800px) {
  .sticky-filter{
    padding-top: 20px;
    background-color: white;
    position: fixed;
    top:0;
    width:100%;
    padding-right: 3%;
    z-index:99999;
  }
  .table-div{
    margin-top: 12rem;
  }
  .incentive-all-table-div{
    margin-top: 19rem;
  }
}


@media only screen and (min-width: 1500px) and (max-width: 1700px) {
  .sticky-filter{
    padding-top: 20px;
    background-color: white;
    position: fixed;
    top:0;
    width:100%;
    padding-right: 3.3%;
    z-index:99999;
  }
  .table-div{
    margin-top: 12rem;
  }
  .incentive-all-table-div{
    margin-top: 19rem;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1500px) {
  .sticky-filter{
    padding-top: 20px;
    background-color: white;
    position: fixed;
    top:0;
    width:100%;
    padding-right: 3.5%;
    z-index:99999;
  }
  .table-div{
    margin-top: 10.6rem;
  }
  .incentive-all-table-div{
    margin-top: 17.6rem;
  }
}
@media only screen and (min-width: 1300px) and (max-width: 1400px) {
  .sticky-filter{
    padding-top: 20px;
    background-color: white;
    position: fixed;
    top:0;
    width:100%;
    padding-right: 3.7%;
    z-index:99999;
  }
  .table-div{
    margin-top: 10.6rem;
  }
  .incentive-all-table-div{
    margin-top: 19.6rem;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  .sticky-filter{
    padding-top: 20px;
    background-color: white;
    position: fixed;
    top:0;
    width:100%;
    padding-right: 4%;
    z-index:99999;
  }
  .table-div{
    margin-top: 14.4rem;
  }
  .incentive-all-table-div{
    margin-top: 21.4rem;
  }
} */

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .table-div {
    margin-top: 2rem;
  }

  .incentive-all-table-div {
    margin-top: 3rem;
  }

}

@media only screen and (min-width: 1230px) {
  .banner-offer-list-popup {
    width: max-content;
  }
}

@media only screen and (min-width: 250px) and (max-width: 767px) {}

.spinner {
  /* Spinner size and color */
  width: 1.5rem;
  height: 1.5rem;
  border-top-color: #444;
  border-left-color: #444;
  color: rgb(165, 165, 165);
  /* Additional spinner styles */
  animation: spinner 400ms linear infinite;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
}

/* Animation styles */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner-slow {
  animation: spinner 1s linear infinite;
}

.loading-td {
  text-align: center;
}

.loading-tr {
  height: 80px;
}

.loading-text {
  margin-left: 8px;
  font-size: 20px;
  color: rgb(165, 165, 165);
}

.text-center {
  text-align: center;
}

.ev-finder-heading {
  font-family: 'Figtree';
  color: #000000;
  font-size: 22px;
}

.filter_label {
  font-size: 14px;
  font-weight: 700;
  color: #2D3648;
}

.filter_select {
  color: #000000;
  font-size: 12px;
  padding: 8px;
  height: 35px;
  padding: 4px 8px 4px 15px;
}

.filter_select_old {
  color: #000000;
  font-size: 14px;
  padding: 8px;
  height: 45px;
}

.filter_select_bold {
  font-weight: 600;
}

.search_btn,
.reset_btn {
  text-wrap: nowrap;
  white-space: nowrap;
  height: 45px;
  font-size: 14px;
  background: #fff;
  border-radius: 4px;
}

.search_btn {
  font-weight: 600;
}

.blank-select-color {
  color: #7b7b7b;
}

.sticky-table-header {
  position: sticky;
  position: -webkit-sticky;
  top: -1px;
  z-index: 999;
  background-color: #0F2213;
  color: #fff;
}

.table-header {
  background-color: #1F4427;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}

.sticky-table-header-old {
  position: sticky;
  position: -webkit-sticky;
  top: -1px;
  background-color: #e9e9e9;
  z-index: 999;
  color: #000;
}

.table-responsive {
  min-height: 740px;
  overflow-x: hidden;
}

.fixed-table {
  border-collapse: collapse;
}

/* .NewThead>tr>:nth-child(1),.NewThead>tr>:nth-child(10),.NewThead>tr>:nth-child(8),.NewTbody>tr>:nth-child(1),.NewTbody>tr>:nth-child(10),.NewTbody>tr>:nth-child(8){
  min-width: 75px;
  max-width: 75px;
  overflow: hidden;
} */


.width-75 {
  min-width: 75px;
  max-width: 75px;
  overflow: hidden;
}

.base_msrp {
  min-width: 75px;
  max-width: 82px;
  overflow: hidden;
}

/* .NewTbody>tr>:nth-child(7),.NewThead>tr>:nth-child(7){
  min-width: 75px;
  max-width: 82px;
  overflow: hidden;
} */
.fix-width {
  min-width: 321px;
  overflow: hidden;
}

.v_class {
  /* min-width: 120px; */
  max-width: 120px;
  white-space: nowrap;
  text-wrap: nowrap;
}

/* .NewThead>tr>:nth-child(5),.NewTbody>tr>:nth-child(5)
{ */
/* min-width: 120px; */
/* max-width: 120px;
  white-space: nowrap;
  text-wrap: nowrap; */
/* } */

.requirement_columns {
  min-width: 50px;
  max-width: 120px;
}

/* .NewTbody>tr>:nth-child(10),.NewTbody>tr>:nth-child(11),.NewTbody>tr>:nth-child(12),.NewTbody>tr>:nth-child(13),.NewThead>tr>:nth-child(10),.NewThead>tr>:nth-child(11),.NewThead>tr>:nth-child(12),.NewThead>tr>:nth-child(13){
  min-width: 50px;
  max-width: 120px;
} */


.fixed-cell-width {}

.footer {
  padding-top: 2rem;
}

.overflow-hidden {
  overflow: hidden !important;
  padding-right: 35px;
}

.table-responsive {
  width: 100%;
}

thead>tr {
  font-weight: 600;
}

.bold_text {
  font-weight: 600;
}

@media only screen and (min-width: 1200px) {
  .filter_input_div {
    /* width: calc(100% - 12%); */
  }

  .filter_search_input_div {
    /* width: calc(100% - 88%); */
  }

  .search_btn,
  .reset_btn {
    width: 125.48px;
  }
}

@media only screen and (max-width: 1200px) {
  .search_btn {
    width: 100%;
  }

  .reset_btn {
    width: 100%
  }
}



.max-fit-content {
  max-width: fit-content;
}

.min-fit-content {
  min-width: fit-content;
}

.error-border {
  border: 1px solid #0f2213 !important;
}

.red-border {
  border: 1px solid red !important;
}

.custom-tooltip {
  font-family: 'Figtree';
  font-size: 14px !important;
  border-radius: 5px;
  opacity: 1 !important;
  font-weight: 400;
  max-width: 300px;
  z-index: 11;
}

.tooltip-inner {
  background: var(--Green-900, #1F4427);
  max-width: fit-content;
  border: 1px solid var(--Dark-Green, #0F2213);
}

.nacs-tooltip {
  min-width: 410px;
  max-width: 410px;
}

.nacs-tooltip>.tooltip-inner {
  max-width: 410px;
}

.tooltip-inner {
  text-align: left;
}


.base-price-tooltip {
  max-width: 300px;
}

.base-price-tooltip>.tooltip-inner {
  max-width: fit-content;
}

.fed-tex-credit-el-tooltip {
  max-width: 300px;
}

.fed-tex-credit-el-tooltip>.tooltip-inner {
  max-width: fit-content;
}

/* .sticky-filter{
  margin-left: -50px;
  padding-left: 50px;
} */

/* .sticky-filter{
  padding: 0 35px;
} */

.tesla-super-charge-tooltip {
  min-width: fit-content;
}

.tesla-super-charge-tooltip>.tooltip-inner {
  max-width: fit-content;
}


.fix-size-tooltip {
  min-width: 500px;
}

.fix-size-tooltip>.tooltip-inner {
  max-width: 500px;
}

.msrp-tooltip {
  min-width: fit-content;
}

.msrp-tooltip>.tooltip-inner {
  max-width: fit-content;
}

.top-border {
  border-top-width: 1px !important;
}

/* Override border style for rows with class 'no-border' */
.no-border {
  border-width: 0 !important;
  border-bottom: none;
}

.incentiveAllTable {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 3px;
}

.verticalAlign {
  vertical-align: top;
}

.marginTop {
  margin-top: 10px;
}

.incentive-all-tbody {
  border-bottom: 1px solid #e5e5e5;
}

.incentive-all-tbody>tr:last-child {
  border-bottom: 1px solid #e5e5e5;
}

.model-td {
  padding-right: 0px !important;
}

/* .trim-td{
  padding-left: 0px !important;
} */
.trim-text {
  font-size: 10px;
  color: #76777A;
}

.hidden_row {
  display: none;
}

.text-right {
  text-align: right !important;
}

.total_potential_savings_td {
  padding-right: 5.4% !important;
}

.new_total_potential_savings_td {
  padding-right: 1% !important;
}

.cursor-pointer {
  cursor: pointer;
}

.form-checkbox .form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url(../img/material-symbols--check.png) !important;
}

.form-checkbox .form-check-input:checked {
  background-color: #fff !important;
  border-color: #000000;
}

.form-check-input:checked {
  background-color: transparent !important;
  border-color: transparent !important;
}

.form-check-input:focus {
  border-color: transparent !important;
  outline: 0;
  box-shadow: none !important;
}

.form-checkbox .form-check-input {
  box-shadow: none !important;
  border-radius: 0 !important;
  border-color: #000000 !important;
}

.form-check .form-check-label {
  font-size: 12px;
  line-height: 1.1;
  font-weight: 500;
  color: #1E1E1F;
}

.option-select .form-check .form-check-label {
  font-size: 14px !important;
  line-height: 1.1 !important;
  font-weight: 400 !important;
  color: #1E1E1F !important;
}

.form-switch .form-check-input {
  --bs-form-switch-bg: url(../img/uncheck.svg) !important;
  background-color: #959699 !important;
}

.form-lable .form-check-label {
  font-size: 16px !important;
}

.car-detail {
  border-bottom: 1px solid #B6B5B5;
}

.car-postential {
  font-size: 14px;
  color: #1E1E1F;
}

.car-lease {
  font-size: 10px;
  color: #1E1E1F;
  font-weight: 500;
}

.tex-detail {
  font-size: 12px;
  color: #1E1E1F;
  font-weight: 500;
}

.btn-Storm-Grey {
  background-color: #f3f3f4;
  font-size: 16px;
  border: 1px solid #1E1E1F;
  box-shadow: none;
  outline: none;
  padding: 10px 25px;
  color: #1E1E1F;
  display: flex;
  align-items: center;
  border-radius: 4px;
  font-weight: 500;
}

.tr-hover:hover {
  background-color: #f3f3f4;
}

.data-bg {
  /* background-color: #f3f3f4; */
  /* background: linear-gradient(90deg, rgba(255, 255, 255, 1) 30%, rgba(243, 243, 244, 1) 100%) no-repeat; */
  background: var(--Bkgd-Gradient, linear-gradient(180deg, #FFF 0%, #F3F3F4 100%));
}

.icon-minus {
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Incentive-img {
  background-image: url(../img/landing-img.png);
  background-repeat: no-repeat;
  background-position: center 25%;
  background-size: cover;
  /* Ensures the background image covers the entire div */
  padding: 32px 20px;
  /* Adds padding for smaller screens */
  margin-top: -80px;
  z-index: 9;
  height: 296px;
  display: flex;
  align-items: center;
}

.text-p-set {
  padding: 0% 7% 0% 1.7%;
  /* Responsive padding */
  max-width: 1220px;
  /* Limits content width on larger screens */
}

.insetive-main {
  gap: 20px;
}

@media only screen and (max-width: 991px) {
  .flex-wapper {
    flex-wrap: wrap;
    height: auto !important;
  }

  .side-bar {
    position: relative;
  }

  .right-bar {
    height: auto !important;
  }
}

/* incentiveDetail */

.linear-bg {
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(243, 243, 244, 1) 100%);
}

.bg-detail-top {
  background-color: #1F4427;
}

.bg-incentive-detail-top {
  background-color: #1F4427;
}

.detail-main {
  /* background-color: #fdfdfe; */
  background: linear-gradient(180deg, #FFFFFF 0%, #F3F3F4 100%);
}

.img-respons {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.img-respons img {
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: center;
}

.detail-img {
  height: 160px;
  overflow: hidden;
  width: 284px;
  vertical-align: middle;
  text-align: center;
  justify-content: center;
  display: flex;
  background: transparent;
}


.detail-img img {
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: center;
}

.text-font {
  font-size: 12px;
}

.model-detail {
  background-color: #fff;
  padding: 32px 0px 0px 0px;
}

.nav-link {
  color: #000 !important;
  background-color: #fdfdfe !important;
  border-bottom: 1px solid #bcbcbc;
  border-radius: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
}

.nav-pills .nav-link {
  border-radius: 0 !important;
}

.nav-pills .nav-link.active {
  color: #1F4427 !important;
  font-weight: 700;
  border-bottom: 3px solid #1F4427;
  font-size: 20px;
}

.font-big {
  font-size: 56px;
}

.font-big1 {
  font-size: 50px;
}

.font-largexl {
  font-size: 24px;
}

.font-px {
  font-size: 20px;
}

.font-small {
  font-size: 12px !important;
}

.font-elvn {
  font-size: 11px;
}

.font-smallx {
  font-size: 10px;
}

.font-large {
  font-size: 16px !important;
}

.font-largeX {
  font-size: 32px;
}

.font-midium {
  font-size: 14px;
}

.font-15 {
  font-size: 15px;
}

.font-13 {
  font-size: 13px;
}

.font-17 {
  font-size: 17px;
}

.font-19 {
  font-size: 19px;
}

.line-h {
  line-height: 0.5;
}

.text-line-h {
  line-height: 1;
}

.font-w8 {
  font-weight: 800;
}

.font-w {
  font-weight: 700 !important;
}

.font-wx {
  font-weight: 600;
}

.font-wxl {
  font-weight: 500 !important;
}

.font-w4 {
  font-weight: 400;
}

.font-w3 {
  font-weight: 300;
}


.font-color-op {
  color: #000000CC;
}

.font-white {
  color: #fff;
}

.font-black {
  color: #000 !important;
}

.font-parag {
  color: #333333;
}

.font-mobile .form-check-label {
  color: #343436 !important;
}

.font-color {
  color: #1E1E1F !important;
}

.text-detaile {
  color: #2D3648;
}

.font-Gunmetal {
  color: #21272A;
}

.white-bg {
  background-color: #fff;
  border-radius: 8px;
}

.border-line {
  border-top: 2px solid #d9d9d9;
  border-bottom: 2px solid #d9d9d9;
}

.main-text-detail {
  border: 2px solid #d9d9d9;
}

.cash-detail {
  border-bottom: 2px solid #d9d9d9;
}

.btn-learn-more {
  background-color: #0F2213;
  color: #fff;
  padding: 12px 25px;
  text-decoration: none;
  border-radius: 4px;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 500;
}

.btn-update-zipcode {
  background-color: #0F2213;
  color: #fff;
  padding: 8px 15px;
  text-decoration: none;
  border-radius: 4px;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 500;
}

.in-active-update-btn {
  background-color: #c3c8c4 !important;
  color: #000 !important;
  padding: 12px 25px;
  text-decoration: none;
  border-radius: 4px;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 500;
}

.btn-learn-more:hover,
.btn-update-zipcode:hover {
  background-color: #1f4427;
}

.size-change {
  padding: 10px 35px;
}

.text-not-Eligible {
  background-color: #2E0909;
  color: #fff;
  padding: 3px 15px;
  border-radius: 50px;
  font-size: small;
}

.text-Eligible {
  background-color: #0F2213;
  color: #fff;
  padding: 3px 15px;
  border-radius: 50px;
  font-size: small;
}

.list-items {
  list-style: none;
}

.text-color {
  color: #c1c7cd;
}

.incentiver-title {
  color: #21272a;
  font-weight: bold;
}

.btn-group {
  background-color: #fff;
  border-color: #d9d9d9;
  color: #000;
  justify-content: center;
}

.btn:first-child:active {
  background-color: #0f2213;
  border-color: #0f2213;
}

.btn-group-main .btn {
  background-color: #fff;
  border-color: #0f2213;
}

.btn-group-main .btn:hover {
  color: #fff;
}

:not(.btn-check)+.btn:active {
  background-color: #0f2213;
  border-color: #0f2213;
}

.btn-group-main {
  min-width: 140px;
}

.btn-group-main-finance {
  min-width: 210px;
}

#footer-actions {
  display: none !important;
}

.clearfix {
  display: none !important;
}

#widget-footer {
  display: none;
}

.radio-check .form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url(../img/radio-button.png) !important;
}

.radio-check .form-check-input:checked {
  background-color: #fff !important;
  border-color: #000000;
}

.radio-check .form-check-input {
  box-shadow: none !important;
  border-radius: 50px !important;
  border-color: #000000 !important;
}

.radio-check .form-check-label {
  font-size: 12px;
}

.form-switch .form-check-input {
  --bs-form-switch-bg: url(../img/uncheck.svg) !important;
  background-color: #959699 !important;
  margin-right: 5px;
  margin-bottom: 5px;
}

.btn-Gradient {
  border: 1px solid #1E1E1F;
  color: #1E1E1F;
  padding: 10px 35px;
  border-radius: 4px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 40%, rgba(243, 243, 244, 1) 100%);
}

.mx-w {
  min-width: 200px;
}

.mx-wt {
  max-width: 47%;
}

.Federal-text {
  border-top: 2px solid #d9d9d9;
  border-bottom: 2px solid #d9d9d9;
}

.mx-h {
  min-height: 815px;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #2e653a !important;
}

.large-spinner-container {
  display: none !important;
}

.large-spinner {
  display: none !important;
}

.sticky-filter {
  position: -webkit-sticky;
  /* For Safari */
  position: sticky;
  top: 10px;
  background-color: white;
  /* Adjust this value based on how much space you want at the top */
  z-index: 10;
  /* Ensure it stays on top of other content */
  transition: top 0.3s ease-in-out;
}

.speedmeter {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mar-left {
  margin-left: 28px;
}

.more-info {
  min-width: 400px;
  padding: 24px 80px 0px 80px;
}

/* .more-info-1 {
  display: block;
}

.more-info-2 {
  display: none;
} */

@media only screen and (max-width: 575px) {

  /* .more-info-1 {
    display: none;
  }

  .more-info-2 {
    display: block;
  } */
  .more-info {
    min-width: 400px;
    padding: 0px !important;
  }

  .text-p-set {
    padding: 25% 5% !important;
  }

  .Incentive-img {
    padding: 0;
  }

  .m-min-height {
    min-height: 100vh;
  }
}

.desktop-view {
  display: block;
  background: linear-gradient(180deg, #FFFFFF 0%, #F3F3F4 100%);
}

.mobile-view {
  display: none;
}

/* @media only screen and (max-width: 767px) {
  .desktop-view {
    display: none;
  }

  .mobile-view {
    display: block;
  }
} */
@media only screen and (max-width: 1030px) {
  .desktop-view {
    display: none;
  }

  .s-NewTbody {
    padding-right: 5%;
    padding-left: 5%;
  }

  .lp-skeleton-row {
    height: 64px !important;
  }

  .mobile-view {
    display: block;
  }

  .ClosestChargingStation-div {
    width: 100%;
    height: 292px !important;
    border: none;
  }

  .text-p-set {
    padding: 13% 8% 5% 8%;
    max-width: 1220px;
  }

  .Incentive-img {
    background-image: url(../img/page.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    /* Ensures the background image covers the entire div */
    padding: 0px 20px;
    /* Adds padding for smaller screens */
    margin-top: -106px;
  }

  .idf-title {
    font-size: 25px !important;
  }

  .idf-p {
    font-size: 15px;
  }

  .idf-text {
    font-size: 12px;
  }

  .text-p-detaile-new {
    padding: 14px 32px 14px 18px !important;
  }

  .banner-popup-mobile {
    width: fit-content !important;
    height: 500px !important;
    overflow: auto !important;
  }

  .animated-text {
    font-size: 16px;
  }

  .rotate-text-container {
    text-align: left !important;
  }

  .banner-offer {
    align-items: start !important;
  }

  .font-pop-m {
    font-size: 13px;
  }

  .font-pop-l {
    font-size: 12px;
  }

  .title-compare {
    font-size: 20px !important;
    margin-bottom: 10px !important;
  }
}

.offcanvas {
  max-width: 90% !important;
}

.detaile-top-sticky {
  position: sticky;
  top: -1px;
  z-index: 99;
}

.saving-detail:hover {
  cursor: pointer;
  text-decoration: underline;
}

.detail-popup {
  position: absolute;
  z-index: 98;
  width: 100%;
  /* min-width: 400px; */
  /* max-width: 400px; */
}

.otp-input {
  width: 8% !important;
  height: 3rem;
  font-size: 25px;
}

.verify-btn {
  padding: 1rem !important;
}

@media only screen and (min-width: 1600px) {
  .otp-input {
    width: 8% !important;
    height: 4rem;
  }
}

@media only screen and (max-width: 1500px) {
  .detail-popup {
    position: absolute;
    min-width: 43.7%;
  }
}

@media only screen and (max-width: 1400px) {
  .detail-popup {
    position: absolute;
    min-width: 43.2%;
  }
}

@media only screen and (max-width: 1300px) {
  .detail-popup {
    position: absolute;
    min-width: 42.8%;
  }
}

@media only screen and (max-width: 1200px) {
  .detail-popup {
    position: absolute;
    min-width: 42.2%;
  }
}

@media only screen and (max-width: 1100px) {
  .detail-popup {
    position: absolute;
    min-width: 41.4%;
  }
}

@media only screen and (max-width: 1000px) {
  .detail-popup {
    position: absolute;
    min-width: 40.8%;
  }
}

@media only screen and (max-width: 992px) {
  .detail-popup {
    position: absolute;
    min-width: 92.8%;
  }

  .more-feature {
    top: 0 !important;
    margin-top: 20px;
  }

}

@media only screen and (max-width: 767px) {
  .detail-popup {
    position: absolute;
    min-width: 91.6%;
  }

  .otp-input {
    width: 12% !important;
    height: 3rem;
  }
}

@media only screen and (max-width: 600px) {
  .detail-popup {
    position: absolute;
    min-width: 89%;
  }

  .otp-input {
    width: 12% !important;
    height: 3rem;
  }
}

@media only screen and (max-width: 500px) {
  .detail-popup {
    position: absolute;
    min-width: 86.8%;
  }

  .otp-input {
    width: 12% !important;
    height: 3rem;
  }

  .detail-incentive-name {
    width: 27rem !important;
  }
}

@media only screen and (max-width: 400px) {
  .detail-popup {
    position: absolute;
    min-width: 83%;
    width: 100%;
  }

  .otp-input {
    width: 12% !important;
    height: 2.5rem;
    padding: 0px;
  }
}

@media only screen and (max-width: 300px) {
  .detail-popup {
    position: absolute;
    min-width: 79%;
    width: 79%;
  }
}

/* IncentiveDetailSearch */

.border-w {
  border: 2px solid #d9d9d9;
}

.table-data {
  overflow-x: auto;
}

.insentive-detaile-search {
  background-image: url(../img/landing-img.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 32px 20px;
  margin-top: -60px;
  position: relative;
  z-index: 9;
  height: 296px;
}

.text-p-detaile {
  padding: 40px 32px 110px 108px;
}

.text-p-detaile-new {
  padding: 14px 32px 14px 45px;
  /* height: 103px; */
}

.text-p-detaile2 {
  padding: 13% 2% 2% 2%;
}

@media only screen and (max-width: 575px) {
  .text-p-detaile2 {
    padding: 15% 2% 2% 0%;
  }
}

@media only screen and (max-width: 425px) {
  .text-p-detaile2 {
    padding: 25% 2% 2% 0%;
  }
}

.insentive-main-layout {
  background-color: #fff;
}

.border-tr {
  border: 1px solid #B5B6B8 !important;
  overflow: visible !important;
}

.border-left {
  border-top-left-radius: 4px;
}

.border-rigth {
  border-top-right-radius: 4px;
}

.border-b-left {
  border-bottom-left-radius: 4px;
}

.border-b-rigth {
  border-bottom-right-radius: 4px;
}

.pdg-set {
  padding: 0.7rem;
}

.height-set {
  height: 35px !important;
}

.search-datile {
  background-color: #B5B6B8;
}

.detile-top {
  border-top: 1px solid #B5B6B8;
  border-bottom: 1px solid #B5B6B8;
}

.width-set {
  width: 217px;
}

.saving-set {
  width: 110px;
}

.btn-reset {
  border: none;
  background-color: transparent;
  text-decoration: underline;
}

/* CompareVehicles-css */
.compare-title {
  font-size: 32px;
  font-weight: 600;
  color: #000;
}

.compare-bg {
  background-color: #fefefe;
}

.bottom-border-samll {
  border-bottom: 0.5px solid #B5B6B8 !important;
}

.bottom-border {
  border-bottom: 1px solid #B5B6B8 !important;
}

.bottom-border-2x {
  border-bottom: 1px solid #B5B6B8 !important;
}

.bottom-border-3x {
  border-bottom: 3px solid #B5B6B8 !important;
}

.compare-mobile {
  display: none;
}

.compare-desktop {
  display: block;
}

.car-img-main {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
  max-width: 200px;
  margin: auto;
}

.car-img-main img {
  width: auto;
  height: 84px;
}

.view-detail {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  /* Start hidden */
  transition: opacity 0.3s ease-in-out;
  /* Smooth fade-in effect */
}

.car-img-main:hover .view-detail {
  opacity: 1;
}

.car-img-main img:hover {
  opacity: 0.2;
}

.car-img-main:hover img {
  opacity: 0.2;
}

.car-img-main:hover .view-detail:hover {
  opacity: 1;
}

.model-detail-pro-detailpage {
  position: absolute;
  /* top: 33px;
  left: -140px; */
  top: 95px;
  left: 48px;
  min-width: 350px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 50%, rgba(244, 244, 244, 1) 100%);
}

.model-detail-zip {
  position: absolute;
  /* top: 95px; */
  /* right: 4.5%; */
  min-width: 250px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 50%, rgba(244, 244, 244, 1) 100%);
}

@media only screen and (max-width: 575px) {
  .compare-mobile {
    display: block;
  }

  .compare-desktop {
    display: none;
  }

  .model-detail-pro-detailpage {
    position: absolute;
    top: 21px;
    left: 10px;
    min-width: 320px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 50%, rgba(244, 244, 244, 1) 100%);
  }

  .model-detail-zip {
    position: absolute;
    /* top: 150px; */
    right: -13%;
    min-width: 270px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 50%, rgba(244, 244, 244, 1) 100%);
  }
}

.change-drop {
  background-color: #fff !important;
  color: #000 !important;
  border-color: #fff !important;
  text-decoration: underline;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.change-drop::after {
  display: none !important;
}

.change-pro {
  position: relative;
}

.model-detail-pro-mobile {
  position: absolute;
  top: 35px;
  right: 0px;
  left: 0px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 50%, rgba(244, 244, 244, 1) 100%);
}

.model-detail-pro {
  position: absolute;
  z-index: 9;
  top: 30px;
  right: 30px;
  min-width: 350px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 50%, rgba(244, 244, 244, 1) 100%);
}

.model-detail-pro-add {
  position: absolute;
  top: 70px;
  min-width: 350px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 50%, rgba(244, 244, 244, 1) 100%);
  left: -115px;
}

@media only screen and (max-width: 1399px) {
  .model-detail-pro {
    position: absolute;
    top: 30px;
    right: 0px !important;
    min-width: 350px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 50%, rgba(244, 244, 244, 1) 100%);
  }
}

@media only screen and (max-width: 1199px) {
  .model-detail-pro {
    position: absolute;
    top: 30px;
    right: 0px !important;
    min-width: 300px !important;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 50%, rgba(244, 244, 244, 1) 100%);
  }
}

@media only screen and (max-width: 991px) {
  .model-detail-pro {
    position: absolute;
    top: 50px;
    right: -50px !important;
    min-width: 300px !important;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 50%, rgba(244, 244, 244, 1) 100%);
  }
}

@media only screen and (max-width: 767px) {
  .model-detail-pro {
    position: absolute;
    top: 50px;
    right: -70px !important;
    min-width: 300px !important;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 50%, rgba(244, 244, 244, 1) 100%);
  }

  .text-p-set {
    padding: 17% 8% 3% 8%;
    max-width: 1220px;
  }
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  /* Optional: semi-transparent background */
  z-index: 9999;
  /* Ensure it's on top of other elements */
}

.font-change .form-check-label {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.side-relative {
  position: relative;
}

.p-relative {
  position: relative;
}

/* .side-bar-mobile{
  position: absolute;
  top: 0;
  min-width: 300px;
  z-index: 9;
  padding: 20px;
  background-color: #fff;
} */

.dots {
  display: none;
}

.user {
  display: block;
}

@media only screen and (max-width: 575px) {
  /* .dots {
    display: block;
  }

  .user {
    display: none;
  } */

  .logoimg {
    width: 60px;
  }

  .popup {
    position: absolute;
    top: 50px;
    right: 10px !important;
    width: 300px !important;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* background-color: #fff; */
    /* background: var(--Green-900, #1F4427); */
    background: linear-gradient(270deg, #F3F3F4 0%, #FFFFFF 66%);
    padding: 20px;
    z-index: 10;
    font-family: 'Figtree';
    margin: 0.5rem 0rem !important;
  }

  .manage-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0F2213;
    border: none;
    padding: 0px 5px !important;
    cursor: pointer;
    font-size: 10px !important;
    width: 100%;
    border-radius: 15px 5px 5px 15px;
    margin: 5px 5px;
    font-weight: 500;
  }

  .Incentive-img {
    background-repeat: no-repeat;
    background-position: center 25%;
    background-size: cover;
    padding: 32px 20px;
    z-index: 9;
    height: 160px;
    display: flex;
    align-items: center;
  }
}

/* footer-css */

.text-small {
  font-size: 10px;
  background-color: #0F2213 !important;
  color: #fff !important;
}

.vehicle-detail-list {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.el-cetegory {
  font-size: 14px;
  font-weight: 700 !important;
  color: #000;
}

@media only screen and (max-width: 575px) {
  .footer-main {
    flex-direction: column;
  }

  .copy-right {
    margin-bottom: 50px;
  }
}

/* IncentiveSummary */

.banner-layout-wapper {
  background: linear-gradient(180deg, #FFFFFF 0%, #F3F3F4 100%);
}

@media only screen and (max-width: 575px) {
  .mx-wt {
    max-width: 100%;
  }
}

.text-Eligible-none {
  background-color: transparent;
  color: #fff;
  padding: 3px 15px;
  border-radius: 50px;
  font-size: small;
}

.selected-lease-term {
  background-color: #0f2213 !important;
  border-color: #0f2213 !important;
  color: #fff;
  pointer-events: none;
}


.compare-change-button,
.vdp-zip-btn {
  border: none;
  background-color: transparent;
  text-decoration: underline;
}

.compare-more-detail-btn {
  border: none;
  background-color: transparent;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.banner-contact-wapper {
  margin-top: -64px;
  background-color: #f8f8f8;
}

.min-margin {
  margin-top: -240px;
  padding: 50px;
}

@media only screen and (max-width: 991px) {
  .min-margin {
    margin-top: -180px;
  }
}

@media only screen and (max-width: 767px) {
  .min-margin {
    margin-top: -300px;
  }
}

@media only screen and (max-width: 575px) {
  .min-margin {
    margin-top: -400px;
    padding: 25px;
  }

  .banner-contact-wapper .container {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 425px) {
  .min-margin {
    margin-top: -400px;
    padding: 25px;
  }

  .banner-contact-wapper .container {
    padding: 0 30px;
  }
}

.contact-img {
  width: 100%;
  height: 296px;
  overflow: hidden;
}

.contact-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* incentive-setting */
.banner-setting-layout .nav-link {
  border: none;

}

.banner-setting-layout .nav-pills {
  border: 1px solid #B5B6B880;
  padding: 10px;
}

.banner-setting-layout .nav-pills .nav-link {
  font-weight: 500 !important;
  color: #1E1E1F !important;
  font-size: 16px;
}

.banner-setting-layout .nav-pills .nav-link.active {
  border: none;
  background-color: #F3F3F4 !important;
  font-weight: 500 !important;
  color: #1E1E1F;
  font-size: 16px;
}

.border-right {
  border-right: 1px solid #DDE1E6;
}

.border-main {
  border: 1px solid #DDE1E6;
}

.setting-border {
  border: 1px solid #B5B6B880;
}

.msg-note {
  border-left: 2px solid #000;
}

.font-t-color {
  color: #B72525;
}

.margin-bottom {
  margin-bottom: 253px;
}

.tab-bg {
  background-color: #fff !important;
}

.all-incentive {
  text-decoration: underline;
  cursor: pointer;
}

.last-updated-date {
  font-weight: 400;
}

.detail-search-link {
  font-size: 12px;
  color: #1E1E1F;
}


/* Login Popup  */

.login-popup-img {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.login-popup-img img {
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.mx-width {
  max-width: 80%;
  margin: auto;
}

/* Sign In Popup  */
.banner-layout-popup {
  background: linear-gradient(180deg, #FFFFFF 0%, #F3F3F4 100%);
  border: 1px solid #DDE1E6;
  border-radius: 8px;
}

.banner-layout-popup-signin {
  background: linear-gradient(180deg, #FFFFFF 0%, #F3F3F4 100%);
  border: 1px solid #DDE1E6;
  border-radius: 8px;
}

.banner-popup-wapper {
  background: linear-gradient(180deg, #FFFFFF 0%, #F3F3F4 100%);
  border: 1px solid var(--Neutral-200, #B5B6B8);
  border-radius: 8px;
}

.btn-account {
  background: linear-gradient(180deg, #FFFFFF 0%, #F3F3F4 100%);
  color: #1E1E1F;
  padding: 13px 25px;
  text-decoration: none;
  border-radius: 4px;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #1E1E1F;
}

.btn-account:hover {
  color: #1E1E1F;
  border: 1px solid #1E1E1F;
}

.btn-account:disabled {
  border: none;
}

.banner-detail-popup {
  background: linear-gradient(270deg, #FFFFFF 26%, #F3F3F4 74%);
  box-shadow: 0px 4px 10px 0px #0000000D;
  border: 1px solid var(--Neutral-300, #959699);
  border-radius: 8px;
}

.bg-banner {
  background: linear-gradient(270deg, #FFFFFF 26%, #F3F3F4 74%);
}


.reset-link {
  font-size: 12px;
  font-weight: 700;
}

.popupclose {
  cursor: pointer;
}

.compare-popup {
  padding: 25px;
  border: 1px solid #000;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: linear-gradient(270deg, #F3F3F4 0%, #FFFFFF 66%);
}

/* compare bottom drawer  */

/* .drawer {
  height: 21%;
  background-color: #ffffff;
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.25);
} */

.drawer__backdrop {
  position: relative !important;
}

.drawer__handle-wrapper {
  display: none !important;
}

.drawer__content {
  padding: 0px !important;
}

.login-popups {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1055;
}

@media only screen and (max-width: 1200px) {
  .login-font {
    font-size: 12px !important;
    padding-top: 1rem !important;
    margin-bottom: 0.5rem;
  }

  .login-popups {
    position: fixed;
    top: 50%;
    left: 35%;
    transform: translate(-27%, -50%);
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1055;
  }

  .content-font {
    font-size: 12px;
  }
}

@media only screen and (max-width: 768px) {
  .login-font {
    font-size: 12px !important;
    padding-top: 1rem !important;
    margin-bottom: 0.5rem;
  }

  .content-font {
    font-size: 10px;
  }

  .login-popups {
    position: fixed;
    top: 50%;
    left: 30%;
    transform: translate(-21%, -50%);
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1055;
  }

  .login-icon {
    width: 20px;
  }

  .Compare-Vehicles-set {
    padding: 100px 0px 64px 52px !important;
  }

  .Compare-Vehicles-img {
    height: auto !important;
  }

  .min-margin {
    margin-top: -180px;
  }

  .contect-text {
    font-size: 50px !important;
  }
}

@media only screen and (max-width: 576px) {
  .login-font {
    font-size: 12px !important;
    padding-top: 1rem !important;
    margin-bottom: 0.5rem;
  }

  .content-font {
    font-size: 10px;
    text-wrap: wrap !important;
  }

  .login-popups {
    position: fixed;
    top: 50%;
    left: 10%;
    transform: translate(-6%, -50%);
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1055;
  }

  .popup-set-width {
    max-width: 530px;
  }

  .login-icon {
    width: 20px;
  }

  .Compare-Vehicles-set {
    padding: 100px 0px 64px 18px !important;
    font-size: 20px !important;
  }

  .m-view {
    margin-bottom: 20px;
  }

  .bg-detail-top {
    background-color: #1F4427;
    text-align: center;
    justify-content: center !important;
  }

  .bg-incentive-detail-top {
    background-color: #1F4427;
    justify-content: center !important;
  }

  .trim-select-div {
    justify-content: center !important;
  }

  .font-big1 {
    font-size: 24px !important;
    font-weight: 700;
  }

  .text-month {
    font-size: 20px !important;
  }

  .annual_mileage_select {
    max-width: 332px;
  }

  .selecter-section {
    text-align: center;
  }

  .m-text-center {
    text-align: center;
  }

  .m-center-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .margin-b {
    margin-bottom: 0 !important;
  }
}

@media only screen and (max-width: 425px) {
  .content-font {
    font-size: 10px;
    text-wrap: wrap !important;
  }

  .popup-set-width {
    max-width: 385px;
  }
}

@media only screen and (max-width: 375px) {
  .login-font {
    font-size: 10px !important;
    padding-top: 1rem !important;
    margin-bottom: 0.5rem;
  }

  .content-font {
    font-size: 8px;
  }

  .login-popups {
    position: fixed;
    top: 50%;
    left: 10%;
    transform: translate(-6%, -50%);
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1055;
  }

  .login-icon {
    width: 15px;
  }

  .btn-font {
    font-size: 12px;
  }

  .popup-set-width {
    max-width: 340px;
  }
  .div-wrap{
    flex-wrap: wrap;
  }
}

.width-fill {
  width: 100%;
}

.main-animation {
  animation: fadeIn 0.5s ease-out forwards;
  opacity: 0;
  /* Start with opacity 0 to be faded in */
}

@media only screen and (max-width: 3000px) {
  .height {
    height: 100vh;
  }

  .height1 {
    height: 100vh;
  }

  .tab-height {
    height: 100vh;
  }

  .signin-width {
    min-width: 775px;
    margin: auto;
  }
}

@media only screen and (max-width: 2250px) {
  .height1 {
    height: 100%;
  }
}

@media only screen and (max-width: 1700px) {
  .page-not-found {
    min-height: 78.2vh !important;
  }
}

@media only screen and (max-width: 1600px) {
  .height1 {
    height: 100%;
  }
}

@media only screen and (max-width: 1600px) {
  .height {
    height: 100%;
  }
}

@media only screen and (max-width: 1030px) {
  .signin-width {
    min-width: auto !important;
    margin: auto;
  }
}


.btn-incentive-finder {
  border: none;
  background-color: transparent;
  text-decoration: underline;
}

.btn-link {
  border: none;
  background-color: transparent;
  text-decoration: underline !important;
}

.error {
  color: red;
}

.svg-icon {
  width: 12px;
  height: 12px;
  overflow: visible;
}

.tooltip-arrow {
  --bs-tooltip-bg: #1F4427;
}

.padding-set {
  padding: 8px 25px;
}

/* .input-height {
  height: 32px;
} */

.signup-width {
  max-width: 680px;
  margin: auto;
}

.button-set {
  background: transparent !important;
  border: none !important;
}

.button-set:disabled {
  background: transparent !important;
  border: none !important;
}

.form-font-set {
  font-size: 16px !important;
}

.link-button {
  text-decoration: underline !important;
  background: transparent !important;
  border: none !important;
}

.position-stick {
  position: sticky;
  top: 0;
  z-index: 99;
}

.header-stick {
  position: sticky;
  top: 0;
  z-index: 100;
}

.filter-sicky {
  position: sticky;
  top: 0px;
  z-index: 10;
}

.table-margin {
  margin-top: 139px;
}

.stick-bg {
  background-color: #1F4427;
}

.table-height {
  height: 1000px;
}

.float-left {
  float: left;
}

.cursor-disable {
  cursor: not-allowed;
}

/* .height-100{
  height: 100%;
} */

/* .blank-page{
  height: 100vh;
} */

@media only screen and (max-width: 2500px) {
  .blank-page {
    min-height: 84.7vh;
  }
}

@media only screen and (max-width: 2400px) {
  .blank-page {
    min-height: 84.2vh;
  }
}

@media only screen and (max-width: 2300px) {
  .blank-page {
    min-height: 83.5vh;
  }
}

@media only screen and (max-width: 2200px) {
  .blank-page {
    min-height: 83.8vh;
  }
}

@media only screen and (max-width: 2100px) {
  .blank-page {
    min-height: 81.8vh;
  }
}

@media only screen and (max-width: 2000px) {
  .blank-page {
    min-height: 82.7vh;
  }
}

@media only screen and (max-width: 1900px) {
  .blank-page {
    min-height: 80vh;
  }
}

@media only screen and (max-width: 1800px) {
  .blank-page {
    min-height: 80.5vh;
  }
}

@media only screen and (max-width: 1700px) {
  .blank-page {
    min-height: 77.5vh;
  }
}

@media only screen and (max-width: 1600px) {
  .blank-page {
    min-height: 78.4vh;
  }
}

@media only screen and (max-width: 1500px) {
  .blank-page {
    min-height: 75.3vh;
  }
}

@media only screen and (max-width: 1400px) {
  .blank-page {
    min-height: 75.3vh;
  }
}

@media only screen and (max-width: 1300px) {
  .blank-page {
    min-height: 75.3vh;
  }
}


.manufacturer-logo-link {
  width: auto;
  /* height: 30px; */
  height: 16px;
}

.disition-icon {
  width: 17px;
  height: 14px;
}

.disition-icon svg {
  width: 17px;
  height: 14px;
}

.user-profile-letter {
  width: 48px;
  height: 48px;
  gap: 10px;
  border-radius: 100px;
  background: var(--Neutral-200, #B5B6B8);
}

.user-profile-letter-header {
  width: 40px;
  height: 40px;
  gap: 10px;
  border-radius: 100px;
  background: #f3f3f4;
}

.user-profile-letter-header>p {
  font-weight: 600;
  line-height: 24px;
  color: #1F4427;
}

.margin-t-set {
  margin-top: 7px;
}

.Compare-Vehicles-img {
  background-image: url(../img/landing-img.png);
  background-position: center;
  background-size: cover;
  margin-top: -108px;
  position: relative;
  z-index: 9;
  height: 296px;
}

.privacy-img {
  background-image: url(../img/landing-img.png);
  background-position: center;
  background-size: cover;
  margin-top: -60px;
  position: relative;
  z-index: 9;
  height: 296px;
}

.Compare-Vehicles-set {
  padding: 100px 0px 64px 125px;
}

@media only screen and (min-width: 1600px) and (max-width: 3300px) {
  .Incentive-Finder {
    min-height: 100vh;
  }
}

@media only screen and (min-width: 1700px) and (max-width: 3300px) {
  .section-height2 {
    height: 100vh;
  }

  .section-margin {
    margin-bottom: 100px;
  }

  .Incentive-img {
    background-position: center 60%;
  }

  .page-not-found {
    min-height: 89.1vh !important;
  }
}


@media only screen and (min-width: 2000px) and (max-width: 3300px) {
  .section-height {
    min-height: 100vh;
  }

  .section-margin {
    margin-bottom: 100px;
  }

  .Incentive-img {
    background-position: center 60%;
  }
}

.fill-width {
  width: 100%;
}

@media only screen and (min-width: 3200px) and (max-width: 3299px) {
  .Model-Year-data {
    padding-left: 20px !important;
  }

  .Segment-data {
    padding-left: 20px;
  }

  .renge-width {
    padding-left: 20px;
  }

  .base-data {
    padding-left: 25px;
  }

  .Potential-data {
    padding-left: 35px;
    width: 14% !important;
  }

  .Potential-Lease {
    width: 7% !important;
  }

  .Federal-data {
    min-width: 31% !important;
  }

  .Incentive-img {
    background-position: center 60%;
  }

  /* requirement */

  .v-model-req {
    width: 30% !important;
  }

  .Model-Year-req {
    width: 18% !important;
  }

  .Segment-req {
    width: 18% !important;
  }

  .renge-req {
    width: 18% !important;
  }

  .base-req {
    width: 18% !important;
  }

  .Federal-req {
    width: 24%;
  }
}

@media only screen and (min-width: 3100px) and (max-width: 3199px) {
  .Model-Year-data {
    padding-left: 19px !important;
  }

  .Segment-data {
    padding-left: 15px;
  }

  .renge-width {
    padding-left: 20px;
  }

  .base-data {
    padding-left: 25px;
  }

  .Potential-data {
    padding-left: 30px;
    width: 14% !important;
  }

  .Potential-Lease {
    width: 7% !important;
  }

  .Federal-data {
    min-width: 31% !important;
  }

  .Incentive-img {
    background-position: center 60%;
  }

  /* requirement */

  .v-model-req {
    width: 30% !important;
  }

  .Model-Year-req {
    width: 18% !important;
  }

  .Segment-req {
    width: 18% !important;
  }

  .renge-req {
    width: 18% !important;
  }

  .base-req {
    width: 18% !important;
  }

  .Federal-req {
    width: 24%;
  }
}

@media only screen and (min-width: 3000px) and (max-width: 3099px) {
  .Model-Year-data {
    padding-left: 17px !important;
  }

  .Segment-data {
    padding-left: 15px;
  }

  .renge-width {
    padding-left: 31px;
  }

  .base-data {
    padding-left: 25px;
  }

  .Potential-data {
    padding-left: 25px;
    width: 14% !important;
  }

  .Potential-Lease {
    width: 7% !important;
  }

  .Federal-data {
    min-width: 31% !important;
  }

  .Incentive-img {
    background-position: center 60%;
  }

  /* requirement */

  .v-model-req {
    width: 30% !important;
  }

  .Model-Year-req {
    width: 18% !important;
  }

  .Segment-req {
    width: 18% !important;
  }

  .renge-req {
    width: 18% !important;
  }

  .base-req {
    width: 18% !important;
  }

  .Federal-req {
    width: 24%;
  }
}

@media only screen and (min-width: 2900px) and (max-width: 2999px) {
  .Model-Year-data {
    padding-left: 15px !important;
  }

  .Segment-data {
    padding-left: 20px;
  }

  .renge-width {
    padding-left: 20px;
  }

  .base-data {
    padding-left: 25px;
  }

  .Potential-data {
    padding-left: 25px;
    width: 14% !important;
  }

  .Potential-Lease {
    width: 7% !important;
  }

  .Federal-data {
    min-width: 31% !important;
  }

  .Incentive-img {
    background-position: center 60%;
  }

  /* requirement */

  .v-model-req {
    width: 30% !important;
  }

  .Model-Year-req {
    width: 18% !important;
  }

  .Segment-req {
    width: 18% !important;
  }

  .renge-req {
    width: 18% !important;
  }

  .base-req {
    width: 18% !important;
  }

  .Federal-req {
    width: 24%;
  }
}

@media only screen and (min-width: 2800px) and (max-width: 2899px) {
  .Model-Year-data {
    padding-left: 15px !important;
  }

  .Segment-data {
    padding-left: 20px;
  }

  .renge-width {
    padding-left: 20px;
  }

  .base-data {
    padding-left: 25px;
  }

  .Potential-data {
    padding-left: 25px;
    width: 14% !important;
  }

  .Potential-Lease {
    width: 7% !important;
  }

  .Federal-data {
    min-width: 31% !important;
  }

  .Incentive-img {
    background-position: center 60%;
  }

  /* requirement */

  .v-model-req {
    width: 30% !important;
  }

  .Model-Year-req {
    width: 18% !important;
  }

  .Segment-req {
    width: 18% !important;
  }

  .renge-req {
    width: 18% !important;
  }

  .base-req {
    width: 18% !important;
  }

  .Federal-req {
    width: 24%;
  }
}

@media only screen and (min-width: 2700px) and (max-width: 2799px) {
  .Model-Year-data {
    padding-left: 14px !important;
  }

  .Segment-data {
    padding-left: 20px;
  }

  .renge-width {
    padding-left: 20px;
  }

  .base-data {
    padding-left: 25px;
  }

  .Potential-data {
    padding-left: 25px;
    width: 14% !important;
  }

  .Potential-Lease {
    width: 8% !important;
  }

  .Federal-data {
    min-width: 30% !important;
  }

  .Incentive-img {
    background-position: center 60%;
  }

  /* requirement */

  .v-model-req {
    width: 30% !important;
  }

  .Model-Year-req {
    width: 18% !important;
  }

  .Segment-req {
    width: 18% !important;
  }

  .renge-req {
    width: 18% !important;
  }

  .base-req {
    width: 18% !important;
  }

  .Federal-req {
    width: 24%;
  }
}

@media only screen and (min-width: 2600px) and (max-width: 2699px) {
  .Model-Year-data {
    padding-left: 14px !important;
  }

  .Segment-data {
    padding-left: 20px;
  }

  .renge-width {
    padding-left: 20px;
  }

  .base-data {
    padding-left: 25px;
  }

  .Potential-data {
    padding-left: 25px;
    width: 14% !important;
  }

  .Potential-Lease {
    width: 8% !important;
  }

  .Federal-data {
    min-width: 30% !important;
  }

  .Incentive-img {
    background-position: center 60%;
  }

  /* requirement */

  .v-model-req {
    width: 30% !important;
  }

  .Model-Year-req {
    width: 18% !important;
  }

  .Segment-req {
    width: 18% !important;
  }

  .renge-req {
    width: 18% !important;
  }

  .base-req {
    width: 18% !important;
  }

  .Federal-req {
    width: 24%;
  }
}


@media only screen and (min-width: 2500px) and (max-width: 2599px) {
  .Model-Year-data {
    padding-left: 14px !important;
  }

  .Segment-data {
    padding-left: 20px;
  }

  .renge-width {
    padding-left: 20px;
  }

  .base-data {
    padding-left: 25px;
  }

  .Potential-data {
    padding-left: 25px;
    width: 14% !important;
  }

  .Potential-Lease {
    width: 9% !important;
  }

  .Federal-data {
    min-width: 28% !important;
  }

  .Incentive-img {
    background-position: center 60%;
  }

  /* requirement */

  .v-model-req {
    width: 30% !important;
  }

  .Model-Year-req {
    width: 18% !important;
  }

  .Segment-req {
    width: 18% !important;
  }

  .renge-req {
    width: 18% !important;
  }

  .base-req {
    width: 18% !important;
  }

  .Federal-req {
    width: 24%;
  }
}

@media only screen and (min-width: 2400px) and (max-width: 2499px) {
  .Model-Year-data {
    padding-left: 14px !important;
  }

  .Segment-data {
    padding-left: 20px;
  }

  .renge-width {
    padding-left: 20px;
  }

  .base-data {
    padding-left: 25px;
  }

  .Potential-data {
    padding-left: 25px;
    width: 14% !important;
  }

  .Potential-Lease {
    width: 9% !important;
  }

  .Federal-data {
    min-width: 27% !important;
  }

  .Incentive-img {
    background-position: center 60%;
  }

  /* requirement */

  .v-model-req {
    width: 30% !important;
  }

  .Model-Year-req {
    width: 18% !important;
  }

  .Segment-req {
    width: 18% !important;
  }

  .renge-req {
    width: 18% !important;
  }

  .base-req {
    width: 18% !important;
  }

  .Federal-req {
    width: 24%;
  }
}

@media only screen and (min-width: 2300px) and (max-width: 2399px) {
  .Model-Year-data {
    padding-left: 13px !important;
  }

  .Segment-data {
    padding-left: 20px;
  }

  .renge-width {
    padding-left: 20px;
  }

  .base-data {
    padding-left: 25px;
  }

  .Potential-data {
    padding-left: 25px;
    width: 14% !important;
  }

  .Potential-Lease {
    width: 9% !important;
  }

  .Federal-data {
    min-width: 26% !important;
  }

  .Incentive-img {
    background-position: center 60%;
  }

  /* requirement */

  .v-model-req {
    width: 30% !important;
  }

  .Model-Year-req {
    width: 18% !important;
  }

  .Segment-req {
    width: 18% !important;
  }

  .renge-req {
    width: 18% !important;
  }

  .base-req {
    width: 18% !important;
  }

  .Federal-req {
    width: 24%;
  }
}

@media only screen and (min-width: 2200px) and (max-width: 2299px) {
  .Model-Year-data {
    padding-left: 12px !important;
  }

  .Segment-data {
    padding-left: 20px;
  }

  .renge-width {
    padding-left: 20px;
  }

  .base-data {
    padding-left: 25px;
  }

  .Potential-data {
    padding-left: 25px;
    width: 14% !important;
  }

  .Potential-Lease {
    width: 10% !important;
  }

  .Federal-data {
    min-width: 26% !important;
  }

  .Incentive-img {
    background-position: center 60%;
  }

  /* requirement */

  .v-model-req {
    width: 30% !important;
  }

  .Model-Year-req {
    width: 18% !important;
  }

  .Segment-req {
    width: 18% !important;
  }

  .renge-req {
    width: 18% !important;
  }

  .base-req {
    width: 18% !important;
  }

  .Federal-req {
    width: 24%;
  }
}

@media only screen and (min-width: 2100px) and (max-width: 2199px) {
  .Model-Year-data {
    padding-left: 11px !important;
  }

  .Segment-data {
    padding-left: 20px;
  }

  .renge-width {
    padding-left: 20px;
  }

  .base-data {
    padding-left: 25px;
  }

  .Potential-data {
    padding-left: 25px;
    width: 14% !important;
  }

  .Potential-Lease {
    width: 10% !important;
  }

  .Federal-data {
    min-width: 26% !important;
  }

  .Incentive-img {
    background-position: center 60%;
  }

  /* requirement */

  .v-model-req {
    width: 30% !important;
  }

  .Model-Year-req {
    width: 18% !important;
  }

  .Segment-req {
    width: 17.5% !important;
  }

  .renge-req {
    width: 18% !important;
  }

  .base-req {
    width: 18% !important;
  }

  .Federal-req {
    width: 24%;
  }
}

@media only screen and (min-width: 2000px) and (max-width: 2099px) {
  .Model-Year-data {
    padding-left: 10px !important;
  }

  .Segment-data {
    padding-left: 20px;
  }

  .renge-width {
    padding-left: 20px;
  }

  .base-data {
    padding-left: 25px;
  }

  .Potential-data {
    padding-left: 25px;
    width: 14% !important;
  }

  .Potential-Lease {
    width: 11% !important;
  }

  .Federal-data {
    min-width: 22% !important;
  }

  .Incentive-img {
    background-position: center 50%;
  }

  /* requirement */

  .v-model-req {
    width: 30% !important;
  }

  .Model-Year-req {
    width: 18% !important;
  }

  .Segment-req {
    width: 17.5% !important;
  }

  .renge-req {
    width: 18% !important;
  }

  .base-req {
    width: 18% !important;
  }

  .Federal-req {
    width: 22%;
  }
}

@media only screen and (min-width: 1900px) and (max-width: 1999px) {
  .Model-Year-data {
    padding-left: 10px !important;
  }

  .Segment-data {
    padding-left: 20px;
  }

  .renge-width {
    padding-left: 20px;
  }

  .base-data {
    padding-left: 25px;
  }

  .Potential-data {
    padding-left: 25px;
    width: 14% !important;
  }

  .Potential-Lease {
    width: 11% !important;
  }

  .Federal-data {
    min-width: 22% !important;
  }

  .Incentive-img {
    background-position: center 60%;
  }

  /* requirement */

  .v-model-req {
    width: 30% !important;
  }

  .Model-Year-req {
    width: 18% !important;
  }

  .Segment-req {
    width: 17.5% !important;
  }

  .renge-req {
    width: 18% !important;
  }

  .base-req {
    width: 18% !important;
  }

  .Federal-req {
    width: 22%;
  }
}

@media only screen and (min-width: 1800px) and (max-width: 1899px) {
  .Model-Year-data {
    padding-left: 5px !important;
  }

  .Segment-data {
    padding-left: 20px;
  }

  .renge-width {
    padding-left: 20px;
  }

  .base-data {
    padding-left: 25px;
  }

  .Potential-data {
    padding-left: 25px;
    width: 14% !important;
  }

  .Potential-Lease {
    width: 12% !important;
  }

  .Federal-data {
    min-width: 20% !important;
  }

  .Incentive-img {
    background-position: center 50%;
  }

  /* requirement */

  .v-model-req {
    width: 30% !important;
  }

  .Model-Year-req {
    width: 18% !important;
  }

  .Segment-req {
    width: 17.5% !important;
  }

  .renge-req {
    width: 18% !important;
  }

  .base-req {
    width: 18% !important;
  }

  .Federal-req {
    width: 22%;
  }
}

@media only screen and (min-width: 1700px) and (max-width: 1799px) {
  .header-width {
    width: 21% !important;
  }

  .v-model {
    width: 26% !important;
    padding-left: 6px;
  }

  .Segment-data {
    padding-left: 10px;
  }

  .renge-width {
    padding-left: 15px;
  }

  .base-data {
    padding-left: 16px;
  }

  .Potential-data {
    padding-left: 22px;
    width: 14% !important;
  }

  .Potential-Lease {
    width: 14% !important;
    padding-left: 5px;
  }

  .Federal-data {
    min-width: 18% !important;
  }

  .Incentive-img {
    background-position: center 40%;
  }

  /* requirement */

  .v-model-req {
    width: 26.5% !important;
  }

  .Model-Year-req {
    width: 18% !important;
  }

  .Segment-req {
    width: 17% !important;
  }

  .renge-req {
    width: 17.5% !important;
  }

  .base-req {
    width: 18% !important;
  }

  .Federal-req {
    width: 20%;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1699px) {
  .v-model {
    width: 25% !important;
  }

  .Model-Year-data {
    width: 12% !important;
  }

  .Segment-data {
    padding-left: 5px;
  }

  .renge-width {
    width: 13% !important;
    padding-left: 4px;
  }

  .base-data {
    padding-left: 3px;
  }

  .Potential-data {
    padding-left: 5px;
  }

  .Federal-data {
    min-width: 17% !important;
    padding-left: 5px !important;
  }

  /* requirement */

  .v-model-req {
    width: 30% !important;
  }

  .Model-Year-req {
    width: 18% !important;
  }

  .Segment-req {
    width: 17.5% !important;
  }

  .renge-req {
    width: 17.5% !important;
  }

  .base-req {
    width: 18% !important;
  }

  .Federal-req {
    width: 20%;
  }
}

@media only screen and (min-width: 1500px) and (max-width: 1599px) {
  .v-model {
    width: 25% !important;
  }

  .Model-Year-data {
    width: 12% !important;
  }

  /* requirement */

  .v-model-req {
    width: 30% !important;
  }

  .Model-Year-req {
    width: 18% !important;
  }

  .Segment-req {
    width: 18% !important;
  }

  .renge-req {
    width: 17.5% !important;
  }

  .base-req {
    width: 18% !important;
  }

  .Federal-req {
    width: 20%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1499px) {
  .v-model {
    width: 24% !important;
  }

  .Model-Year-data {
    width: 12% !important;
    padding-left: 6px;
  }

  .Segment-data {
    padding-left: 6px;
  }

  .renge-width {
    padding-left: 6px;
  }

  .base-data {
    width: 14% !important;
    padding-left: 2px;
  }

  .Potential-data {
    padding-left: 3px;
  }

  .Federal-data {
    min-width: 11% !important;
  }

  /* requirement */

  .v-model-req {
    width: 30% !important;
  }

  .Model-Year-req {
    width: 18% !important;
  }

  .Segment-req {
    width: 18% !important;
  }

  .renge-req {
    width: 17.5% !important;
  }

  .base-req {
    width: 18% !important;
  }

  .Federal-req {
    width: 20%;
  }
}

@media only screen and (min-width: 1300px) and (max-width: 1399px) {
  .v-model {
    width: 25% !important;
  }

  .Model-Year-data {
    width: 12% !important;
  }

  .base-data {
    width: 13% !important;
  }

  .Potential-data {
    width: 14% !important;
  }

  .Federal-data {
    min-width: 10.8% !important;
  }

  /* requirement */

  .v-model-req {
    width: 30% !important;
  }

  .Model-Year-req {
    width: 18% !important;
  }

  .Segment-req {
    width: 18% !important;
  }

  .renge-req {
    width: 17.5% !important;
  }

  .base-req {
    width: 18% !important;
  }

  .Federal-req {
    width: 20%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1299px) {
  .v-model {
    width: 24% !important;
  }

  .Model-Year-data {
    width: 13% !important;
  }

  .base-data {
    width: 13% !important;
  }

  .Potential-data {
    width: 16% !important;
  }

  .Federal-data {
    min-width: 11% !important;
  }

  /* requirement */

  .v-model-req {
    width: 30% !important;
  }

  .Model-Year-req {
    width: 18% !important;
  }

  .Segment-req {
    width: 18% !important;
  }

  .renge-req {
    width: 17.5% !important;
  }

  .base-req {
    width: 18% !important;
  }

  .Federal-req {
    width: 20%;
  }
}

@media only screen and (min-width: 1031px) and (max-width: 1199px) {
  .v-model {
    width: 24% !important;
  }

  .Model-Year-data {
    width: 13% !important;
  }

  .base-data {
    width: 13% !important;
  }

  .Potential-data {
    width: 12% !important;
  }

  .Federal-data {
    min-width: 10% !important;
  }

  /* requirement */

  .v-model-req {
    width: 30% !important;
  }

  .Model-Year-req {
    width: 18% !important;
  }

  .Segment-req {
    width: 18% !important;
  }

  .renge-req {
    width: 17.5% !important;
  }

  .base-req {
    width: 18% !important;
  }

  .Federal-req {
    width: 20%;
  }
}

.otp-container {
  justify-content: center;
}

.popup-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
}

.close-white {
  --bs-btn-close-opacity: 1 !important;
}

.margin-b {
  margin-bottom: 24px;
}

.margin-l {
  margin-left: 22px;
}

.Purchase-Detail-img {
  width: 370px;
  height: 230px;
  overflow: hidden;
}

.Purchase-Detail-img img {
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: center;
}

.charging-w {
  max-width: 125px;
}

.home-charging-w {
  max-width: 135px;
}

/* .account-detail {
  display: none;
  position: absolute; 
  top: 100%; 
  left: 0;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000;
} */

/* .user-hover:hover .account-detail {
  display: block;
} */

.account-detail {
  display: none;
  position: absolute;
  right: 17px;
  padding: 10px;
  background: transparent;
}

.account-detail>div {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #1F4427;
  padding: 10px;
  z-index: 100;
  text-align: left;
  border-radius: 8px;
}

.user-profile:hover .account-detail {
  display: block;
}

.header-set {
  position: relative;
  z-index: 100;
}

.toggle-switch>input:checked {
  background-color: #2E653A !important;
}

.account-detail>p {
  font-family: 'Figtree';
}


/* Styling for the popup */
.popup {
  position: absolute;
  top: 50px;
  right: 20px;
  width: 350px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* background-color: #fff; */
  /* background: var(--Green-900, #1F4427); */
  background: linear-gradient(270deg, #F3F3F4 0%, #FFFFFF 66%);
  padding: 20px;
  z-index: 10;
  font-family: 'Figtree';
}

.popup-content {
  text-align: center;
}

.profile-popup-email {
  font-size: 14px;
  color: #555;
  font-weight: 700;
}

.company {
  font-size: 12px;
  color: #999;
}

.profile-icon {
  margin: 20px 0;
  width: 60px;
  height: 60px;
  background-color: #ddd;
  border-radius: 50%;
  font-size: 36px;
  line-height: 60px;
  display: inline-block;
}

.profile-name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Actions */
.actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* Make actions take the full width of popup */
}

.manage-btn {
  display: flex;
  /* Use flexbox for alignment */
  align-items: center;
  /* Center items vertically */
  justify-content: center;
  /* Center items horizontally */
  background-color: #0F2213;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 12px;
  width: 100%;
  /* Set width to 100% to fill available space */
  border-radius: 15px 5px 5px 15px;
  margin: 5px 5px;
  /* Add vertical margin between buttons */
  font-weight: 500;
}

.signout-btn {
  display: flex;
  /* Use flexbox for alignment */
  align-items: center;
  /* Center items vertically */
  justify-content: center;
  /* Center items horizontally */
  background-color: #0F2213;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 12px;
  width: 100%;
  /* Set width to 100% to fill available space */
  border-radius: 5px 15px 15px 5px;
  margin: 5px 0;
  /* Add vertical margin between buttons */
  font-weight: 500;
}




.manage-btn:hover,
.signout-btn:hover {
  background-color: #1f4427;
}

.profile-popup-icon {
  width: 16px;
  /* Set a fixed width for icons */
  height: 16px;
  /* Set a fixed height for icons */
  margin-right: 8px;
  /* Space between icon and text */
}

/* Close button */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  cursor: pointer;
  color: #888;
}

.close-btn:hover {
  color: #555;
}

.brand-logo {
  /* cursor: default; */
  cursor: pointer;
}

button:disabled {
  background-color: #c3c8c4 !important;
  background: #c3c8c4 !important;
}

.disable {
  background-color: #c3c8c4 !important;
  color: #fff !important;
  font-size: 16px;
  padding: 12px;
}

.share-link:hover span {
  color: #0f2213 !important;
}

.share-link:hover path {
  fill: #0f2213 !important;
}

svg {
  overflow: visible;
}

.small-space {
  margin-left: 2px;
}

.height-0-5 {
  height: 50px;
}

.height-1 {
  height: 112px;
}

.height-2 {
  height: 269px;
}

.height-3 {
  height: 542px;
}

.height-4 {
  height: 815px;
}

.height-5 {
  height: 98px;
}

.height-6 {
  height: 1000px;
}

.height-7 {
  height: 312px;
}

.height-8 {
  height: 238px;
}

span:has(.height-1) {
  display: flex;
}

.close-popup {
  cursor: pointer;
}

.b-margin {
  margin-bottom: 127px;
}

.b-padding {
  padding-bottom: 250px;
}

.height-c-1 {
  height: 102px;
}

.height-c-2 {
  height: 192px;
}

.height-c-3 {
  height: 91px;
}

.full-height {
  height: 100%;
}

.td-bottom-border {
  border-bottom: 1px solid #b5b6b8 !important;
  border-top: 1px solid #b5b6b8 !important;
  overflow: visible !important;
}

.max-Potential {
  max-width: 150px;
}

.maximum-w {
  max-width: 90px;
}

.blur-text {
  opacity: 0.25;
}

.warning-msg {
  font-size: 14px;
  font-weight: 600;
}

.warning-msg-red {
  font-size: 14px;
  font-weight: 600;
  color: #b74134 !important;

}

.Bi-Directional img {
  width: 80%;
}

.landing-page-image {
  height: -webkit-fill-available;
  width: auto;
}

.evf-img {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.evf-img img {
  width: 100%;
  height: 100px;
  object-fit: contain;
  object-position: center;
}

.detail-incentive-name {
  width: 60rem;
}

.detail-incentive-amount-div {
  display: flex;
  width: 100%;
  /* justify-content: flex-start; */
  justify-content: flex-end;
}


/* .detail-incentive-amount {} */

/* Drawer base styling */
.drawer {
  position: fixed;
  left: 0;
  width: 100%;
  background: #f9f9f9;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.3);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: bottom 0.3s ease;
}

/* When the drawer is open */
.drawer.open {
  bottom: 0;
  display: block;
}

.close-drawer {
  display: none;
  background: #15256d;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.checkbox-list label {
  display: block;
  margin: 10px 0;
}

.sticky-tabs {
  position: sticky;
  top: 1px;
  /* Adjust based on your header height if you have one */
  z-index: 10;
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
}

.sticky-tabs .nav-item {
  align-items: self-end;
  flex: 1;
  display: flex;
  justify-content: center;
  text-align: center;
}

.sticky-position-section {
  position: sticky;
  top: 128px;
  /* Adjust based on the height of your sticky tabs section */
  z-index: 9;
}

.tab-container-h {
  height: 127px;
}

.reset-btn-p {
  position: absolute;
  top: 10px;
  left: 0;
}

.ClosestChargingStation-div {
  width: 100%;
  height: 830px;
  border: none;
}

.offcanvas.offcanvas-start {
  width: 65%;
}

.lazy-load-image-background {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  /* Customize duration and timing */
}

.lazy-load-image-loaded {
  opacity: 1;
}

.btn-group:hover {
  color: #000 !important;
}

.detail-incentive-amount {
  text-align: end;
}

.NewTbody {
  overflow: visible;
}

.lp-detail-btn {
  height: 32px;
  /* position: absolute; */
  position: absolute;
  /* margin-left: 5.5rem; */
  padding: 10px 10px;
  margin-bottom: 1rem;
  margin-right: 3.4rem;
  margin-bottom: 4.7rem;
}

.lp-detail-btn-mb {
  height: 32px;
  /* position: absolute; */
  /* position: absolute; */
  display: block;
  /* margin-left: 5.5rem; */
  padding: 12px 12px 12px 12px;
  /* margin-right: 5rem; */
  /* margin-bottom: 1rem; */
  display: flex;
  align-items: center;
}

.mobile-trim-row {
  /* padding: 5px 0px 10px 0px; */
  padding-top: 0px;
  padding-bottom: 0.1rem;
}

.banner-offer {
  /* background-color: #fff; */
  background: linear-gradient(180deg, #FFFFFF 0%, #F3F3F4 100%);
  margin-top: 0px;
  /* height: 40px; */
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
}

.rotate-text-container {
  /* font-size: 14px; */
  /* font-weight: bolder; */
  text-align: center;
  overflow: hidden;
  color: #1E1E1F;
  /* height: 40px; */
  /* Consistent height */
  /* line-height: 40px; */
  /* Vertically center text */
}

@keyframes rotateAnimation {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@keyframes stayRotation {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.in-transition {
  animation: rotateAnimation 1s forwards;
  /* Fade-out animation (1 second) */
}

.display {
  animation: stayRotation 1s forwards;
  /* Fade-in animation (1 second) */
}



.wrapped-text {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  /* Adjust as needed */
}

.text-item {
  white-space: nowrap;
}

.banner-learn-more {
  text-decoration: underline;
  cursor: pointer;
}

.banner-detail-popup {
  background: linear-gradient(270deg, #FFFFFF 26%, #F3F3F4 74%);
}

.banner-offer-explore-btn {
  padding: 12px;
  font-size: 16px;
}

.banner-popup-header {
  font-size: 18px;
}

.font-18 {
  font-size: 18px;
}

.font-22 {
  font-size: 22px;
}


.banner-popup-explore-div {
  flex-wrap: wrap;
}

@media only screen and (min-width: 930px) {
  .banner-popup-header {
    text-wrap: nowrap;
  }

  .banner-offer-explore-btn {
    /* height: 40px; */
    min-width: 12rem;
  }

  .banner-popup-explore-div {
    flex-wrap: nowrap;
  }

  .banner-offer {
    height: 56px;
  }

}

/* @media only screen and (max-width: 929px) {
  .dropdown-container {
    top: 150px !important;
  }
}

@media only screen and (max-width: 329px) {
  .dropdown-container {
    top: 145px !important;
  }
} */


.green-hover-text:hover {
  color: #1f4427 !important;
}

.page-not-found {
  min-height: 80vh;
}

.offcanva-down {
  padding: 10px;
  /* Add some padding for spacing */
}

@media (max-width: 768px) {
  .offcanva-down {
    padding: 8px;
    font-size: 14px;
    /* Adjust size for smaller screens */
  }
}

@media (max-width: 480px) {
  .offcanva-down {
    padding: 6px;
    font-size: 12px;
  }
}

.offcanva-down {
  cursor: pointer;
}

.dropdown-container {
  position: absolute;
  background: linear-gradient(270deg, #FFFFFF 26%, #F3F3F4 74%);
  padding: 10px;
  z-index: 101;
  max-width: fit-content;
  display: inline-block;
  margin: auto;
  border-radius: 8px;
  right: 0;
  left: 0;
}

.dropdown-content {
  padding: 10px 20px;
}

.banner-contain {
  height: 40px;
}

.rotate-text-container {
  flex-grow: 1;
  /* Allows this to take up available space */
}

.text-list::marker {
  font-size: 10px;
}

.header-gb {
  /* background-color: #0f2213; */
  background-color: #1F4427;
}

.banner-toggle-section {
  background: linear-gradient(0deg, #FFF 10%, #F3F3F4 100%);
}

.option-select .form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url(../img/radio-button.png) !important;
}

.option-select .form-check-input {
  border-color: #000 !important;
}

.options-btn {
  background: linear-gradient(180deg, #FFFFFF 0%, #F3F3F4 100%);
  border: 1px solid var(--Neutral-800, #1E1E1F);
  padding: 7px 26px;
  border-radius: 4px;
}

.hr {
  border: 1px solid #B5B6B8;
  width: 100%;
}

.btn-pdd-set {
  padding: 8px 15px;
}

.p-absolute {
  position: absolute;
}

.mt-4-5 {
  margin-top: 2rem;
}

.my-4-5 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.py-4-5 {
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.detail-popup-top {
  top: 42px;
}

.detail-popup-top-double {
  top: 25px;
}

.detail-popup-top-double-helf {
  top: 40px;
}

.overflow-x {
  overflow-x: hidden;
}

.border-T {
  border-top: 1px solid #D9D9D9;
}

.mx-fit-content {
  max-width: fit-content;
  margin: auto;
}

.Programs-available {
  margin-bottom: 16px;
}

.landing-check .form-check .form-check-label {
  font-size: 14px;
  font-weight: 400;
  color: #000 !important;
}

.landing-lable {
  background: var(--Green-700, #2E653A);
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  padding: 1px 9px;
  border-radius: 16px;
}

/* .new-lable-w{
  width: 300px;
} */

.border-margin {
  margin: 0px 55px 0px 48px;
}

.pagination {
  display: flex;
  justify-content: center;
}

.pagination ul {
  display: flex;
  list-style: none;
  align-items: center;
  padding-left: 0;
}

.pagination .previous a {
  color: #1E1E1F;
  text-decoration: none;
}

.pagination .next {
  rotate: 270deg;
}

.pagination .previous {
  rotate: 90deg;
}

.pagination .next a {
  color: #1E1E1F;
  text-decoration: none;
}

.pagination .disabled a {
  opacity: 0.4;
  text-decoration: none;
}

.pagination li a {
  font-size: 14px;
  font-weight: 400;
  color: var(--Neutral-800, #1E1E1F);
  padding: 0px 15px;
}

.pagination .selected {
  background: #1F4427;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}

.pagination .selected a {
  color: #fff !important;
  font-size: 14px;
  font-weight: 700;
}

.sort-by {
  position: relative;
  bottom: 10px;
}

.mx-vehical {
  margin: 0px;
}

.sticky-detail-tabs {
  position: sticky;
  top: 100px;
  background: #FFF;
  z-index: 98;
}

.top-b {
  border-top: 2px solid #d9d9d9;
}

.bottom-b {
  border-bottom: 2px solid #d9d9d9;
}

.lp-skeleton-row {
  height: 45px;
}

.react-loading-skeleton {
  --base-color: #f3f3f3 !important;
  /* --highlight-color: #d4d4d4 !important; */
  --highlight-color: #D0D0D2 !important;
}

.list-style-none {
  list-style: none;
}

.cursor-default {
  cursor: default;
}

.tab-content {
  position: relative;
  z-index: 9;
}

.d-contents {
  display: contents;
}

.maximum-h {
  height: 500px;
}

.border-none {
  border: none !important;
}

.detail-img span {
  width: 100%;
}

.visibility-hidden {
  visibility: "hidden";
}

.hw-0 {
  height: 0px !important;
  width: 0px !important;
}

.lazy-load-image-loaded:has(> .hw-0) {
  height: 0px !important;
  width: 0px !important;
}

.w-126 {
  width: 126px;
}

.pb-4-5 {
  padding-bottom: 2rem;
}

.margin-min {
  margin-top: -5px;
}

.vdp-top-zip-input {
  top: 12px
}

.zip-error {
  color: #b74134 !important;
}

.zip-error-border {
  /* border: 1px solid #b74134; */
}

.zip-input {
  width: 90%;
}

.padding-zip {
  padding-left: 7%;
}

.zip-focus::placeholder {
  font-weight: 600;
  font-size: 12px;
}

.ps-s-3 {
  padding-left: 17px !important;
}

.ps-s-2 {
  padding-left: 24px !important;
}